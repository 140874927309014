@import '@/styles/_global';
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~swiper/scss";
@import "~swiper/scss/pagination";
@import "~swiper/scss/autoplay";
@import "~swiper/scss/grid";

@layer components {
  .Custom__container {
    @apply mobile:max-w-[351px] sm:max-w-[611px] md:max-w-[721px] lg:max-w-[998px] xl:max-w-[1210px] 2xl:max-w-[1520px] laptop:max-w-[1824px] mx-auto;
  }
  .Container__overflow {
    @apply w-screen overflow-hidden;
  }
  .Card {
    @apply pt-7 pb-[26px] px-6;
  }
  .Card__heading {
    @apply text-primary-blue text-3xl leading-[32px] font-baskervville capitalize -tracking-[1px] mb-4 md:mb-8 lg:text-4xl lg:mr-4 lg:-tracking-[1.62px] xl:text-5xl xl:mb-12 laptop:text-6xl;
  }
  .Card__description {
    @apply text-secondary-gray text-base leading-[22px] font-raleway mb-4 md:mb-8 xl:mb-12 xl:w-[30%] lg:text-lg laptop:text-xl lg:text-right lg:tracking-[0.4px];
  }
  .Footer__headingLink {
    @apply text-primary-blue text-xl font-bold font-raleway leading-[30px] cursor-pointer mt-2 hover:text-blue-800;
  }
  .Footer__link {
    @apply text-stone-500 text-base font-semibold font-raleway leading-normal cursor-pointer hover:text-stone-600;
  }
  .Shadow {
    @apply duration-300 ease-in-out shadow-md dark:shadow-black/30;
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

:root {
  --primary-blue: #1a325d;
  --secondary-gray: #686868;
  --tw-gradient-stops: #000 0%, rgba(0, 0, 0, 0) 100%;
  --font-baskervville: "Baskerville" sans-serif;
  --font-raleway: "Raleway" sans-serif;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}
.scroll-lock {
  overflow: hidden;
  position: fixed;
  inset: 0;
}
.container {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: toRem(12);
  padding-right: toRem(12);
}
.wide-container {
  max-width: 100%;
  width: 100%;
}
.orient-turn {
  display: none;
}
// @media (max-width: 1000px) and (orientation: landscape) {
//   .orient-turn {
//     display: grid;
//     position: fixed;
//     height: 100%;
//     width: 100%;
//     top: 0;
//     left: 0;
//     background-color: #006aaf;
//     z-index: 15;
//     pointer-events: none;
//     place-items: center;
//   }
//   .orient-content {
//     width: 60%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//   }
//   .orient-img {
//     background-image: url(https://static-assets-web.flixcart.com/batman-returns/batman-returns/p/images/landscape-e85e99.png);
//     background-position: 50%;
//     background-size: contain;
//     background-repeat: no-repeat;
//     width: 30%;
//     padding-bottom: 25.5%;
//   }

//   header,
//   main,
//   footer {
//     display: none;
//   }
// }

.headFootSprite{
  background-image: url(../../assets/icons/header-footer-sprite.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 0 0;
}
.homeSprite{
  background-image: url(../../assets/icons/home-sprite.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 0 0;
}

@media (min-width: 600px) {
  .container {
    // width: 600px;
    // max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 19.2px;
    padding-right: 19.2px;
  }
  .wide-container {
    // max-width: 600px;
    // width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

div.Carousel__bottom_only .swiper-pagination {
  width: auto;
  bottom: 22px;
}

div.Carousel__bottom_only .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background: transparent;
  opacity: 0.4;
  border: 1.5px solid white;
}

div.Carousel__bottom_only .swiper-pagination-bullet-active {
  width: 15px;
  height: 15px;
  background: #fff !important;
  opacity: 1;
  border: 1.5px solid white;
}

.Carousel__bottom_only .swiper-pagination {
  position: unset;
  margin-top: 24px;
}

@include mobile(){
  .Carousel__bottom_only .swiper-pagination {
    position: unset;
    margin-top: 10px !important;
  }

  .Carousel__blueBullet .swiper-pagination {
    margin-top: 20px;
  }

  .Carousel__blueBullet_new .swiper-pagination {
    margin-top: 20px;
  }
}
@media (min-width: 1100px) {
  .wide-container {
    max-width: 100%;
    width: 100%;
  }
  html {
    font-size: 16px;
  }
  .container {
    // max-width: 1280px;
    // width: 1280px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .serviceMenu{
    background-position: 44px -1118px;
  }
}
@media (min-width: 1600px) {
  html {
    font-size: 20px;
  }
  .serviceMenu{
    background-position: 62px -1117px;
  }
  // .container {
  //   max-width: 1600px;
  //   width: 1600px;
  // }
}
@media (min-width: 1920px) {
  .serviceMenu{
    background-position:78px -1116px;
  }
  html {
    font-size: 24px;
  }
  .container {
    max-width: 1920px;
    width: 1920px;
  }
}

div#CertificationBanner .swiper-pagination {
  text-align: left;
  left: 25px;
  margin-bottom: 35px;

  .swiper-pagination-bullet {
    background: transparent;
  }

  .swiper-pagination-bullet-active {
    background: var(--primary-blue);
  }
}

div.Carousel__white_left_dot .swiper-pagination {
  width: auto;
  bottom: 22px;
  left: 28px;
}

div.Carousel__white_left_dot .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background: transparent;
  opacity: 0.4;
  border: 1.5px solid linear-gradient(30deg, #B38728, #FBF5B7) !important;;
}

div.Carousel__white_left_dot .swiper-pagination-bullet-active {
  width: 15px;
  height: 15px;
  opacity: 1;
  border: 1.5px solid white;
}

div.Carousel__bottom_only .swiper-pagination {
  width: auto;
  bottom: 22px;
}

div.Carousel__bottom_only .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background: transparent;
  opacity: 0.4;
  border: 1.5px solid white;
}

div.Carousel__bottom_only .swiper-pagination-bullet-active {
  width: 15px;
  height: 15px;
  background: #fff !important;
  opacity: 1;
  border: 1.5px solid white;
}


.Carousel .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: transparent;
  opacity: 0.4;
  border: 1px solid white;
}

.Carousel__alignEnd > .swiper-wrapper {
  align-items: flex-end;
}

.Carousel .swiper-pagination-bullet {
  background: white;
  opacity: 1;
}

.Carousel__bigBullet .swiper-pagination-bullet {
  height: 15px;
  width: 15px;
  border: 1px solid var(--primary-blue);
}

.Carousel__blueBullet .swiper-pagination {
  position: unset;
  margin-top: 24px;
}

.Carousel__blueBullet_new .swiper-pagination {
  position: unset;
}

.Carousel__bottom_only .swiper-pagination {
  position: unset;
  margin-top: 24px;
}

@include mobile(){
  .Carousel__bottom_only .swiper-pagination {
    position: unset;
    margin-top: 10px !important;
  }

  .Carousel__blueBullet .swiper-pagination {
    margin-top: 40px;
  }

  .Carousel__blueBullet_new .swiper-pagination {
    margin-top: -20px;
  }
}

.Carousel__withPreview .swiper-slide,
.Carousel__videoPreview .swiper-slide,
.Carousel__newsletter .swiper-slide,
.Carousel__specialization .swiper-slide {
  width: 90%;
}

.Carousel__flex .swiper-slide {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.Carousel__hAuto .swiper-slide {
  height: auto;
}

.Carousel__specialization .swiper-slide {
  height: auto;
}

.Carousel__gradient {
  box-shadow: -4px 4px 54px 0px rgba(0, 0, 0, 0.2);
}

@screen md {
  .Carousel__withPreview .swiper-slide {
    width: 75%;
  }

  .Carousel__videoPreview .swiper-slide {
    width: 65%;
  }

  .Carousel__newsletter .swiper-slide {
    width: 55%;
  }
}

@screen lg {
  .Carousel__videoPreview .swiper-slide {
    width: 30%;
  }

  .Carousel__newsletter .swiper-slide {
    width: 35%;
  }
  .Carousel__specialization .swiper-slide {
    width: auto;
  }
}

@screen laptop {
  .Carousel__newsletter .swiper-slide {
    width: 30%;
  }
}

// Hotfix
html {
  overflow-x: hidden;
}

.swiper-pagination-bullet,
.swiper-pagination span {
  height: toRemDesktop(19) !important;
  width: toRemDesktop(19) !important;
}

.swiper-pagination .swiper-pagination-bullet {
  border: 1.5px solid rgba(26, 50, 93, 0.4);
  opacity: 1;
  background: rgba(255, 255, 255, 0);
}


.swiper-pagination span.swiper-pagination-bullet-active {
  background-color: #1a325d;
}

.homepage .swiper-pagination {
  text-align: center;
  padding-left: toRemDesktop(10);
  padding-bottom: toRemDesktop(10);
}

.swiper {
  height: auto;
}

.swiper-slide {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.swiper-slide img.bannerImg {
  transform: scale(1.5);
  transition-duration: 3s;
}
.swiper-slide-active img.bannerImg{
  transform: scale(1);
  z-index: 9;
}
/* Width and height of the scrollbar track and thumb */
.program-menu *::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
.program-menu *::-webkit-scrollbar-track {
  background: #e7e7e7;
}

/* Thumb */
.program-menu *::-webkit-scrollbar-thumb {
  background: #1a325d;
  border-radius: 6px;
}

/* On hover */
.program-menu * ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Firefox scrollbar */
.program-menu * {
  scrollbar-width: thin;
  scrollbar-color: #1a325d #e7e7e7;
}

// Hotfix
// body {
//   overflow-x: hidden;
// }

div#programlist .swiper-no-swiping {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-end;
}
.engt.engt-mobile-tab .engt-left-theme,.engt.engt-mobile-tab .engt-right-theme {
  z-index: 100;
}

@media (max-width: 768px) {
  .engt-launch-icon-box {
    bottom: 98px !important;
    right: 0 !important;
  }
}

@media (min-width: 1280px) {
  div#programlist .swiper-no-swiping {
    align-items: flex-end;
  }

  div.Carousel__white_left_dot .swiper-pagination {
    bottom: 50px;
    left: 50px;
  }

  div#blogs-listing-banner .swiper-pagination {
    right: toRemDesktop(60);
    bottom: toRemDesktop(60);
    left: auto;
  }

  div#CertificationBanner .swiper-pagination {
    left: toRemDesktop(76);
    margin-bottom: toRemDesktop(62);
  }
}

.border-no-width{
  border: none !important;
}

.sideButtons .sidebar-call-btn img{
  max-width: unset;
  max-height: unset;
  width: toRemDesktop(38);
  height: toRemDesktop(38);
}

.padding-0-imp{
  padding: 0 !important;
}
@include mobile{
  .home-marque-style > div {
    margin: 10px 11px;
    overflow: hidden;
  }
  .home-marque-style > div > div{
    border-radius: 10px;
  }
  .home-marque-style > div > div> p{
    font-weight: bold;
    font-size: 11px;
  }
}
@include desktop{
  .home-marque-style > div {
     display: none;
  }
  .home-marque-style-new > div {
    display: block;
 }
}


/////////////////////// This is for AMP Pages please don't delete ///////////////
@media (max-width: 768px) {
  .rfidiv {
    flex-direction: column;
    padding: 1rem;
    align-items: flex-start !important;
    margin: 2.25rem 0px 0.66666667rem 0rem;
  }

  .rfidiv h2 {
    font-size: 1.5rem !important;
    width: 100%;
    margin-bottom: 2rem !important;
  }

  .rfidiv a {
    text-align: center;
    font-size: 14px;
    padding: 9px;
    margin: 0;
  
  }
  .h2Title{
    max-width: 100% !important;
  }
}


.rfidiv{
  width : 100%; 
  background-color: #1c325c;
  color: #ffffff;
  margin: 3.3333333333rem 1rem 1rem 0;
  padding: 1.6666666667rem 1.6666666667rem 1.6666666667rem 1.6666666667rem;
  border-radius: 1.3333333333rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.h2Title{
  margin: 0 !important; 
  font-size: 24px; 
  line-height: 1.2;
  color:#ffffff; 
  width: 100%; 
  text-transform: capitalize; 
  max-width: 60%;
  color: #fff !important;
  font-family: var(--font-baskervville) !important
}

.requestACall{
 background: transparent;
 color: #FFF !important;
 border: 2px solid #fff;
 text-wrap: nowrap; 
 margin-right: .5rem;
 font-size: .8333333333rem;
 letter-spacing: .025rem;
 display: inline-block; 
 padding: 0.5rem 1rem; 
 border-radius: 50px; 
 text-decoration: none !important; 
 font-weight: 600; 
 transition: background-color 0.3s, color 0.3s; 
 text-transform: uppercase;
}

.requestACall:hover{
  color: #1a325d !important;
  border-color: #fff;
  background-color: #fff !important;
  box-shadow: 0 4px 24px 0 #07142c;
}

.enrollNowCTA{
color: #1c325c !important;
border-color: #1a325d;
background: #ffc907;
height: 2.5rem;
padding: .75rem 1rem;
display: inline-flex;
justify-content: center;
align-items: center;
border-radius: 999px;
border: 1.5px solid #1a325d;
text-wrap: nowrap; 
margin-right: .5rem;
font-size: .8333333333rem;
letter-spacing: .025rem;
display: inline-block; 
padding: 0.5rem 1rem; 
border-radius: 50px; 
text-decoration: none; 
font-weight: 600; 
transition: background-color 0.3s, color 0.3s; 
text-transform: uppercase;
text-decoration: none !important;
}

.enrollNowCTA:hover{
  box-shadow: 0 4px 24px 0 #07142c;
  color: #fff
}

div#new-lead-form h3.error-title {
  font-size: 2.2rem;
  margin: 10px 0;
}


#preDegree .hero.section-header-desing-2 .section-container .wrapper .main-content-wrapper {
  max-width: 1320px;
  margin: 0 auto;
}




#preDegree .hero.section-header-desing-2 .section-container .wrapper .main-content-wrapper {
  max-width: 1320px;
  margin: 0 auto;
}
#preDegree .section-header-desing-2 .html-section-body {
  background-color: #ffffff;
  position: relative;
  padding: 80px 0;
}
#preDegree .section-header-desing-2 .wrapper {
  background-color: #12162e;
  position: relative;
}
#preDegree .section-header-desing-2 .bg-pattern-img-top {
  position: absolute;
  top: -20px;
  left: 76.54px;
  height: 68.54px;
  z-index: 1;
}
#preDegree .section-header-desing-2 .bg-pattern-img-bottom {
  position: absolute;
  bottom: -60px;
  width: 100%;
  max-width: 468px;
  left: 20%;
  z-index: 1;
}
#preDegree .section-header-desing-2 .main-content-wrapper {
  position: relative;
  padding: 113px 24px;
  align-items: center;
  justify-content: space-between;
}
#preDegree .section-header-desing-2 .header-left-section {
  display: flex;
  flex-direction: column;
  gap: 33px;
  width: 50%;
  max-width: 600px;
}
#preDegree .section-header-desing-2 .header-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 19px;
  margin: 0 3rem 0 0;
}
#preDegree .section-header-desing-2 .section-heading {
  color: #ffffff;
  margin: 0;
}
#preDegree .section-header-desing-2 h1 {
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
}
#preDegree .section-header-desing-2 .header-content-desc {
  color: #d9d9d9;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  margin: 0px 5rem 0 0;
}
#preDegree .section-header-desing-2 .header-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  text-decoration: none;
  background-image: linear-gradient(270deg, #ff4f18 0%, #fa9816 130.28%);
  border-radius: 50px;
  padding: 13px 34px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  z-index: 5;
}
#preDegree .section-header-desing-2 .header-btn:hover {
  background-image: linear-gradient(270deg, #ff4f18 0%, #ff4f18 130.28%);
  color: white;
}
#preDegree .section-header-desing-2 .header-right-section {
  position: absolute;
  top: -35px;
  right: 24px;
  bottom: -35px;
  z-index: 2;
  display: flex;
}
#preDegree .section-header-desing-2 .right-section-img {
  height: 100%;
  margin-left: auto;
}
@media (max-width: 1440px) {
  #preDegree .section-header-desing-2 .bg-pattern-img-bottom {
    bottom: -9%;
    max-width: 370px;
  }
  #preDegree .section-header-desing-2 .main-content-wrapper {
    padding: 60px 24px;
    max-width: 1116px;
  }
  #preDegree .section-header-desing-2 .header-left-section {
    width: 55%;
  }
  #preDegree .section-header-desing-2 .header-right-section {
    top: -20px;
    right: 24px;
    bottom: -20px;
  }
}
@media only screen and (max-width: 1200px) {
  #preDegree .section-header-desing-2 .section-container .wrapper .main-content-wrapper {
    max-width: 936px;
    margin: 0 auto;
  }
  #preDegree .section-header-desing-2 .header-left-section {
    width: 50%;
  }
  #preDegree .section-header-desing-2 .header-content-wrapper {
    gap: 10px;
  }
  #preDegree .section-header-desing-2 h1.section-heading {
    font-size: 31px;
    line-height: 42px;
  }
  #preDegree .section-header-desing-2 .header-right-section {
    right: 24px;
  }
}
@media (min-width: 1024px) {
  #preDegree .edw-limitedwidth-block .section-header-desing-2 .html-section-body {
    padding: 70px 0;
  }
  #preDegree .edw-limitedwidth-block .section-header-desing-2 .main-content-wrapper {
    padding: 30px 24.02px;
    display: flex;
    max-width: unset;
  }
  #preDegree .edw-limitedwidth-block .section-header-desing-2 .bg-pattern-img-top {
    top: 22.77px;
    left: 40.85px;
    z-index: 1;
    height: 36.58px;
  }
  #preDegree .edw-limitedwidth-block .section-header-desing-2 .bg-pattern-img-bottom {
    width: 250px;
    bottom: -25px;
    left: 140px;
  }
  #preDegree .edw-limitedwidth-block .section-header-desing-2 .header-content-wrapper {
    gap: 10px;
    margin-right: 0;
  }
  #preDegree .edw-limitedwidth-block .section-header-desing-2 h1.section-heading {
    font-size: 26px;
    line-height: 125%;
  }
  #preDegree .edw-limitedwidth-block .section-header-desing-2 .header-content-desc {
    font-size: 14px;
    line-height: 22px;
  }
  #preDegree .edw-limitedwidth-block .section-header-desing-2 .header-right-section {
    top: -18.67px;
    right: 24px;
    bottom: -20.09px;
  }
}
@media (max-width: 1024px) {
  #preDegree .section-header-desing-2 .html-section-body {
    padding: 70px 0;
  }
  #preDegree .section-header-desing-2 .section-container .wrapper .main-content-wrapper {
    max-width: 820px;
  }
  #preDegree .section-header-desing-2 .main-content-wrapper {
    padding: 30px 24.02px;
    display: flex;
  }
  #preDegree .section-header-desing-2 .bg-pattern-img-top {
    top: 22.77px;
    left: 40.85px;
    z-index: 1;
    height: 36.58px;
  }
  #preDegree .section-header-desing-2 .bg-pattern-img-bottom {
    width: 250px;
    bottom: -25px;
    left: 140px;
  }
  #preDegree .section-header-desing-2 .header-left-section {
    max-width: 450px;
  }
  #preDegree .section-header-desing-2 .header-content-wrapper {
    gap: 10px;
  }
  #preDegree .section-header-desing-2 h1.section-heading {
    font-size: 26px;
    line-height: 125%;
  }
  #preDegree .section-header-desing-2 .header-content-desc {
    font-size: 14px;
    line-height: 22px;
  }
  #preDegree .section-header-desing-2 .header-right-section {
    top: -18.67px;
    right: 24px;
    bottom: -20.09px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  #preDegree .section-header-desing-2 .html-section-body {
    padding: 50px 0;
  }
  #preDegree .section-header-desing-2 .header-right-section {
    width: 40%;
    top: 30.67px;
    right: 24px;
    bottom: 30.09px;
  }
  #preDegree .section-header-desing-2 .header-left-section {
    width: 60%;
  }
}
@media (max-width: 767.5px) {
  #preDegree .section-header-desing-2 .html-section-body {
    padding-top: 80px;
    padding-bottom: 50px;
  }
  #preDegree .section-header-desing-2 .bg-pattern-img-top {
    top: 84px;
    left: 0px;
    height: 44.04px;
    transform: rotate(82.057deg);
  }
  #preDegree .section-header-desing-2 .bg-pattern-img-bottom {
    bottom: -26px;
    width: 80%;
    max-width: 200px;
    left: 12%;
  }
  #preDegree .section-header-desing-2 .section-container .wrapper .main-content-wrapper {
    max-width: 600px;
  }
  #preDegree .section-header-desing-2 .main-content-wrapper {
    flex-direction: column-reverse;
    padding: 0px 24.02px 146.44px 24.02px;
  }
  #preDegree .section-header-desing-2 .header-left-section {
    width: 100%;
    text-align: center;
    align-items: center;
    gap: 37px;
  }
  #preDegree .section-header-desing-2 .header-content-wrapper {
    gap: 22px;
    margin: 0;
  }
  #preDegree .section-header-desing-2 .section-heading {
    margin-top: 40px;
  }
  #preDegree .section-header-desing-2 h1.section-heading {
    font-weight: 700;
    font-size: 42px;
    line-height: 55px;
  }
  #preDegree .section-header-desing-2 .header-content-desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
  }
  #preDegree .section-header-desing-2 .header-right-section {
    position: static;
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  #preDegree .section-header-desing-2 .right-section-img {
    width: 100%;
    margin: auto;
    margin-top: -40.77px;
  }
}




#preDegree .card.section-courses-new-design-7 {
  padding-top: 50px;
  padding-right: 24px;
  padding-bottom: 50px;
  padding-left: 24px;
}
#preDegree .card.section-courses-new-design-7 .section-container {
  max-width: 1320px;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
}
#preDegree .card.section-courses-new-design-7 .section-heading {
  color: #444;
  text-align: center;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
#preDegree h2.section-heading {
  font-size: 40px;
  font-weight: 500;
  line-height: 1;
}
#preDegree .card.section-courses-new-design-7 .card-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 40px;
  margin-top: 40px;
}
#preDegree .card.section-courses-new-design-7 .course-card {
  width: calc(25% - 18px);
  transition-behavior: normal;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
  transition-delay: 0s;
  transition-property: box-shadow;
  display: flex;
  flex-wrap: wrap;
}
#preDegree .card.section-courses-new-design-7 .course-card .card-img {
  width: 100%;
  position: relative;
  height: 208px;
}
#preDegree .card.section-courses-new-design-7 .course-card .card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#preDegree .card.section-courses-new-design-7 .course-card .card-img .class-tag {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  padding-top: 5px;
  padding-right: 0px;
  padding-bottom: 5px;
  padding-left: 0px;
  background-color: #5a61ff;
  min-width: 120px;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
#preDegree .card.section-courses-new-design-7 .course-card .card-content {
  width: 100%;
  height: calc(100% - 208px);
  padding-top: 28px;
  padding-right: 20px;
  padding-bottom: 28px;
  padding-left: 20px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: #ebebeb;
  border-right-color: #ebebeb;
  border-bottom-color: #ebebeb;
  border-left-color: #ebebeb;
  background-color: white;
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  column-gap: 22px;
}
#preDegree .card.section-courses-new-design-7 .course-card .card-content .card-desc {
  color: #555;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
#preDegree .card.section-courses-new-design-7 .course-card .card-content .card-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  text-decoration-line: none;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  padding-top: 2px;
  padding-right: 16px;
  padding-bottom: 2px;
  padding-left: 16px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: #9a3cdf;
  border-right-color: #9a3cdf;
  border-bottom-color: #9a3cdf;
  border-left-color: #9a3cdf;
  background-color: white;
  color: #9a3cdf;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  transition-behavior: normal, normal;
  transition-duration: 0.3s, 0.2s;
  transition-timing-function: ease, ease;
  transition-delay: 0s, 0s;
  transition-property: background-color, color;
  cursor: pointer;
}
#preDegree .card.section-courses-new-design-7 .course-card .card-content .card-btn:hover {
  background-color: #9a3cdf;
  color: white;
}
#preDegree .card.section-courses-new-design-7 .course-card:nth-child(4n+1) .card-img .class-tag {
  background-color: #5a61ff;
}
#preDegree .card.section-courses-new-design-7 .course-card:nth-child(4n+2) .card-img .class-tag {
  background-color: #2aa636;
}
#preDegree .card.section-courses-new-design-7 .course-card:nth-child(4n+3) .card-img .class-tag {
  background-color: #ef8888;
}
#preDegree .card.section-courses-new-design-7 .course-card:nth-child(4n+4) .card-img .class-tag {
  background-color: #ecb13e;
}
#preDegree .card.section-courses-new-design-7 .course-card:hover {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 15px 60px 0px;
}
#preDegree .card.block-content > .section-courses-new-design-7 > .container-fluid > .card-container > .course-card > .card-img > .class-tag {
  padding-right: 5px;
  padding-left: 5px;
}
@media screen and (max-width: 1024px) and (min-width: 600px) {
  #preDegree .card.section-courses-new-design-7 .section-container {
    max-width: 820px;
  }
  #preDegree .card.section-courses-new-design-7 .section-container .course-card {
    max-width: 534px;
  }
  #preDegree .card.section-courses-new-design-7 .course-card {
    width: 100%;
    margin: 0 auto;
  }
  #preDegree .card.section-courses-new-design-7 .course-card .card-img {
    width: 50%;
    height: 100%;
  }
  #preDegree .card.section-courses-new-design-7 .course-card .card-img img {
    position: absolute;
    top: 0;
    left: 0;
  }
  #preDegree .card.section-courses-new-design-7 .course-card .card-content {
    width: 50%;
    height: unset;
  }
  #preDegree .card.section-courses-new-design-7 .course-card .card-content .card-desc {
    text-align: left;
  }
  #preDegree .card.section-courses-new-design-7 .course-card .card-content .card-btn {
    margin: auto 0 0;
  }
}
@media screen and (max-width: 767.5px) {
  #preDegree .card.section-courses-new-design-7 .section-container {
    max-width: 600px;
  }
}
@media screen and (max-width: 600px) {
  #preDegree .card.section-courses-new-design-7 .course-card {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  #preDegree .card.edw-limitedwidth-block .section-courses-new-design-7 .section-container {
    max-width: 820px;
  }
  #preDegree .card.edw-limitedwidth-block .section-courses-new-design-7 .section-container .course-card {
    max-width: 534px;
  }
  #preDegree .card.edw-limitedwidth-block .section-courses-new-design-7 .course-card {
    width: 100%;
    margin: 0 auto;
  }
  #preDegree .card.edw-limitedwidth-block .section-courses-new-design-7 .course-card .card-img {
    width: 50%;
    height: 100%;
  }
  #preDegree .card.edw-limitedwidth-block .section-courses-new-design-7 .course-card .card-img img {
    position: absolute;
    top: 0;
    left: 0;
  }
  #preDegree .card.edw-limitedwidth-block .section-courses-new-design-7 .course-card .card-content {
    width: 50%;
    height: unset;
  }
  #preDegree .card.edw-limitedwidth-block .section-courses-new-design-7 .course-card .card-content .card-desc {
    text-align: left;
  }
  #preDegree .card.edw-limitedwidth-block .section-courses-new-design-7 .course-card .card-content .card-btn {
    margin: auto 0 0;
  }
}






#preDegree .section-team-design-1 {
  padding-top: 50px;
  padding-right: 24px;
  padding-bottom: 50px;
  padding-left: 24px;
  background-image: url("https://qa-remui.edwiser.org/staticcdn/CDN/teamdesign3/images/bg-image.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 43%;
  background-position-y: 50%;
}
#preDegree .section-team-design-1 .section-container {
  max-width: 1320px;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
}
#preDegree .section-team-design-1 .section-heading {
  color: #313848;
  text-align: center;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
#preDegree .section-team-design-1 h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: 1;
}
#preDegree .section-team-design-1 .section-desc {
  color: #4c5a73;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 3px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  max-width: 872px;
}
#preDegree .section-team-design-1 .card-container {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 24px;
  column-gap: 24px;
}
#preDegree .section-team-design-1 .team-card {
  width: calc(25% - 18px);
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: #d5ddea;
  border-right-color: #d5ddea;
  border-bottom-color: #d5ddea;
  border-left-color: #d5ddea;
  background-color: white;
}
#preDegree .section-team-design-1 .team-card .img-box {
  width: 100%;
  height: 330px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  overflow-x: hidden;
  overflow-y: hidden;
}
#preDegree .section-team-design-1 .team-card .img-box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center top;
}
#preDegree .section-team-design-1 .team-card .name {
  color: #313848;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-top: 16px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
#preDegree .section-team-design-1 .team-card .desc {
  color: #4c5a73;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin-top: 6px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
#preDegree .section-team-design-1 > .section-container > .card-container > .team-card > .img-box > img {
  width: 250px;
  height: 158px;
}
#preDegree .section-team-design-1 > .section-container > .card-container > .team-card {
  width: 270px;
  height: 178px;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}
#preDegree .section-team-design-1 > .section-container > .card-container > .team-card > .img-box > a > img {
  width: 250px;
  height: 158px;
}
#preDegree .section-team-design-1 > .section-container > .card-container > .team-card > .img-box {
  width: 250px;
  height: 158px;
}
#preDegree .section-team-design-1 > .section-container > .card-container > .team-card > .name {
  line-height: 21px;
  font-size: 18px;
}
#preDegree .section-team-design-1 {
  padding-bottom: 70px;
}
@media screen and (max-width: 1200px) and (min-width: 1025px) {
  #preDegree .section-team-design-1 .team-card {
    padding: 12px;
  }
}
@media screen and (min-width: 1024px) {
  #preDegree .edw-limitedwidth-block .section-team-design-1 .section-container {
    max-width: 820px;
  }
  #preDegree .edw-limitedwidth-block .section-team-design-1 .team-card {
    width: calc(50% - 12px);
  }
}
@media screen and (max-width: 1024px) {
  #preDegree .section-team-design-1 .section-container {
    max-width: 820px;
  }
  #preDegree .section-team-design-1 .team-card {
    width: calc(50% - 12px);
  }
}
@media screen and (max-width: 768px) {
  #preDegree .section-team-design-1 .section-container {
    max-width: 600px;
  }
  #preDegree .section-team-design-1 .card-container {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    row-gap: 45px;
  }
  #preDegree .section-team-design-1 .team-card {
    width: unset;
    max-width: 350px;
    margin: 0 auto;
  }
}






#preDegree .section-steps-design-1 {
  padding: 50px 24px;
}
#preDegree .section-steps-design-1 .section-container {
  max-width: 1320px;
  margin: 0 auto;
}
#preDegree .section-steps-design-1 .section-heading {
  color: #313848;
  text-align: center;
  margin: 0;
}
#preDegree .section-steps-design-1 h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: 1;
}
#preDegree .section-steps-design-1 .section-desc {
  color: #4c5a73;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 11px 0 0;
}
#preDegree .section-steps-design-1 .card-container {
  display: flex;
  margin-top: 40px;
  gap: 24px;
  row-gap: 40px;
}
#preDegree .section-steps-design-1 .steps-card {
  width: 25%;
}
#preDegree .section-steps-design-1 .steps-card .card-head {
  width: 77px;
  height: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px 40px 2px 2px;
  border: 1px solid #FF4F18;
  background-color: #FFF;
}
#preDegree .section-steps-design-1 .steps-card .card-head p {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin: 10px 5px 0 0;
  background-image: linear-gradient(to left, #ff4f18, #fa9816);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#preDegree .section-steps-design-1 .steps-card .card-heading {
  color: #313848;
  margin: 22px 0 0;
}
#preDegree .section-steps-design-1 .steps-card h3 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
#preDegree .section-steps-design-1 .steps-card .card-desc {
  color: #4c5a73;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 4px 0 0;
}
@media screen and (min-width: 1024px) {
  #preDegree .edw-limitedwidth-block .section-steps-design-1 .section-container {
    max-width: 820px;
  }
  #preDegree .edw-limitedwidth-block .section-steps-design-1 .card-container {
    flex-wrap: wrap;
  }
  #preDegree .edw-limitedwidth-block .section-steps-design-1 .steps-card {
    width: calc(50% - 12px);
  }
}
@media screen and (max-width: 1024px) {
  #preDegree .section-steps-design-1 .section-container {
    max-width: 820px;
  }
  #preDegree .section-steps-design-1 .card-container {
    flex-wrap: wrap;
  }
  #preDegree .section-steps-design-1 .steps-card {
    width: calc(50% - 12px);
  }
}
@media screen and (max-width: 767.5px) {
  #preDegree .section-steps-design-1 .section-container {
    max-width: 600px;
  }
  #preDegree .section-steps-design-1 .section-container .steps-card {
    max-width: 350px;
  }
  #preDegree .section-steps-design-1 .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  }
  #preDegree .section-steps-design-1 .steps-card {
    width: unset;
    margin: 0 auto;
  }
}



#preDegree .section-team-design-5 {
  padding: 50px 24px;
  background: #FFF;
}
#preDegree .section-team-design-5 .section-container {
  max-width: 1320px;
  margin: 0 auto;
}
#preDegree .section-team-design-5 .wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
#preDegree .section-team-design-5 .left-section {
  width: calc(25% - 12px);
  width: calc(25% - 18px);
  margin-top: 94px;
  padding-top: 9.4px;
}
#preDegree .section-team-design-5 .left-section .section-heading {
  color: #313848;
  margin: 0;
}
#preDegree .section-team-design-5 .left-section h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: 1;
}
#preDegree .section-team-design-5 .left-section .left-card-desc {
  color: #4c5a73;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin: 5px 0 0;
}
#preDegree .section-team-design-5 .card-container {
  width: calc(75% - 12px);
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
}
#preDegree .section-team-design-5 .team-card {
  width: calc(33% - 16px);
  margin-top: 94px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
#preDegree .section-team-design-5 .team-card .card-head {
  position: relative;
  padding-top: 237px;
  border-radius: 4px 137px 0px 0px;
  border: 1px solid #CDCDCD;
}
#preDegree .section-team-design-5 .team-card .img-box {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 330.297px;
  overflow: hidden;
}
#preDegree .section-team-design-5 .team-card .img-box img {
  height: 100%;
  object-fit: cover;
}
#preDegree .section-team-design-5 .team-card .cardBody {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 9px 24px 12px;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #CDCDCD;
  border-top: 0;
  background: #FFF;
  max-height: fit-content;
}
#preDegree .section-team-design-5 .team-card .name {
  color: #313848;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
}
#preDegree .section-team-design-5 .team-card .desc {
  color: #4c5a73;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
}
#preDegree .section-team-design-5 .team-card:hover .name, #preDegree .section-team-design-5 .team-card:hover .desc {
  background: linear-gradient(to left, #ff4f18, #fa9816);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 1440px) and (min-width: 1025px) {
  #preDegree .section-team-design-5 .left-section {
    margin: auto 0;
  }
  #preDegree .section-team-design-5 .team-card {
    margin-top: 46px;
  }
  #preDegree .section-team-design-5 .team-card .img-box {
    height: 281px;
  }
}
@media screen and (min-width: 1024px) {
  #preDegree .edw-limitedwidth-block .section-team-design-5 .section-container {
    max-width: 820px;
  }
  #preDegree .edw-limitedwidth-block .section-team-design-5 .left-section {
    width: 100%;
    text-align: center;
    margin-top: 0;
  }
  #preDegree .edw-limitedwidth-block .section-team-design-5 .card-container {
    width: 100%;
    justify-content: center;
    gap: 38px;
  }
  #preDegree .edw-limitedwidth-block .section-team-design-5 .team-card {
    width: 100%;
    max-width: 534px;
    margin-bottom: 8px;
  }
  #preDegree .edw-limitedwidth-block .section-team-design-5 .team-card:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1024px) {
  #preDegree .section-team-design-5 .section-container {
    max-width: 820px;
  }
  #preDegree .section-team-design-5 .left-section {
    width: 100%;
    text-align: center;
    margin-top: 0;
  }
  #preDegree .section-team-design-5 .card-container {
    width: 100%;
    justify-content: center;
    gap: 38px;
  }
  #preDegree .section-team-design-5 .team-card {
    width: 100%;
    max-width: 534px;
    margin-bottom: 8px;
  }
  #preDegree .section-team-design-5 .team-card:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1024px) {
  #preDegree .section-team-design-5 .section-container {
    max-width: 600px;
  }
}
#preDegree .box a {
  padding: 15px;
  background-color: #fff;
  border-radius: 3px;
  text-decoration: none;
}
#preDegree .modal {
  position: fixed;
  inset: 0;
  background: rgba(35, 58, 119, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
#preDegree .content {
  position: relative;
  background: white;
  padding: 1em 2em;
  border-radius: 4px;
}
#preDegree .modal:target {
  display: flex;
}


.closeModel{
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fe0606;
  font-size: 30px;
  text-decoration: none;
}
.carousel-caption {
  position: absolute;
  top: 50%;
  left: 5rem;
  width: 45%;
  border-radius: 1rem;
  transform: translateY(-50%);
  z-index: 9;
}
@include mobile() {
  .carousel-caption {
        left: 1rem;
        width: calc(100% - 2rem);
        top: 3%;
        bottom: auto;
        position: absolute;
        transform: translateY(0);
  }
}
.banner-content{
  background-color: rgba(255, 255, 255, 0.79);
  position: relative;
  padding: 2rem;
  z-index: 3;
  color: #1a325d;
  z-index: 9;
  border-radius: 1rem;
  width: 100%;
  box-shadow: -.1666666667rem .1666666667rem 2.25rem 0 rgba(0,0,0,.15);
}
// @include mobile() {
//   .banner-content {
//     background-color:transparent;
//     backdrop-filter:none;
//     -webkit-backdrop-filter: none !important;
//     box-shadow:none;
//     padding: 1rem;
//   }
// }

@media only screen and (max-width: 767px) {
  .banner-content {
    background-color: transparent;
    backdrop-filter: none;
    -webkit-backdrop-filter: none !important;
    box-shadow: none;
    padding: 1rem;
  }
}


.banner-content h1, .banner-content h2{
  font-size: 2vw;
  margin-bottom: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: normal;
  color: #1a325d;
  text-align: left;
  font-family: var(--font-raleway);
  line-height: 1.2;
}
.second-slide {
  font-size: 2.0vw;
  margin-bottom: 20px; 
  text-transform: uppercase; 
  font-weight: normal;
  color: #1a325d;
  text-align: left; 
  font-family: var(--font-raleway);
  line-height: 1.2;
}
@include mobile() {
  .banner-content h1, .banner-content h2{
    font-size: 1.3rem;
    margin-bottom: 20px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: normal;
    color: #1a325d;
    text-align: left;
    font-family: var(--font-raleway);
    line-height: 1.2;
  }
  .second-slide{
    font-size: 1.3rem;
    margin-bottom: 20px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: normal;
    color: #1a325d;
    text-align: left;
    font-family: var(--font-raleway);
    line-height: 1.2;
  }
}
.banner-content h1 img.hcl-logo{
  max-width: 220px;
  margin-top: -10px;
}
.banner-collab{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.banner-collab p{
  text-transform: uppercase;
  font-size: 0.9rem;
}
.banner-collab img{
  max-width: 100px;
}
.banner-content h5{
  font-size: 1.1vw;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  display: inline-block;
  align-items: center;
  color: #1A325D;
  text-align: left;
}
@include mobile() {
  .banner-content h5{
    font-size: 1rem;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    display: inline-block;
    align-items: center;
    color: #1A325D;
    text-align: left;
  }
}

.banner-content h5 img{
  max-height: 26px;
  max-width: 110px;
  display: inline !important;
  margin-left: 10px;
}
.banner-content p{
  font-weight: 600;
  display: flex;
  align-items: center;
}
.banner-course{
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
}
@include mobile() {
  .banner-course {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 8px;
  }
}
.banner-course-item{
  // border-right: 1px solid #000;
  // padding: 0px 20px;
  // text-align: center;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  
    border-right: 1px solid #000;
    padding: 4px 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    box-shadow: 2px 2px 5px #00000033;
    border: none;
    border-radius: 3px;
    min-width: 90px;
}
@include mobile(){
  .banner-course-item{
        border-right: 1px solid #000;
    padding: 3px 4px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 3px;
    min-width: auto;
    width: 30%;
  }
}
.banner-course-item:first-child{
  // padding-left: 0px;
}
.banner-course-item:last-child{
  // border-right: none;
  // padding-right: 0px;
}
.banner-course-item h5{
  font-size:16px;
  margin: 0px;
  font-weight:bold;
  color: #1A325D;
}
@include mobile(){
 .banner-course-item h5{
  font-size:14px;
  margin: 0px;
  font-weight:bold;
  color: #1A325D;
}
}
.banner-course-item p{
  margin: 0px;
  color: #000;
  font-size: 12px;
}
@include mobile(){
.banner-course-item p{
  margin: 0px;
  color: #000;
  font-size:9px;
}
}
.homepage .swiper-pagination{
  text-align: left;
  bottom: -10px !important;
  margin-left: 40px;
  margin-bottom: 21.57px;
}
.banner-content p img{
  max-width: 200px;
}

.banner-content span, .prog-banner-content span
{
font-size: 16px;
font-family: var(--font-raleway);
}
.banner-content span b, .prog.banner-content span b
{
font-size: 16px;
}
div#Carousel .swiper-button-prev {
  left: 0;
}
div#Carousel .swiper-button-next {
  right: 0;
}

div#Carousel .swiper-button-prev, div#Carousel .swiper-button-next {
  padding: 8px;
  background: #ffffffaa;
  height: 60px;
  opacity: 0.5;
}
div#Carousel .swiper-button-prev:hover, div#Carousel .swiper-button-next:hover {
  opacity: 1;
}

div#Carousel .swiper-button-prev:after, div#Carousel .swiper-button-next:after {
  color: #1A325D;
  font-size: 18px;
}
.text-nowrap{
  text-wrap: nowrap;
}
@include desktop {
  .absolute.bottom-0.left-0.right-0.p-4.flex.justify-center.z-50 {
    bottom: 40px;
} 
}
@include mobile {
  .absolute.bottom-0.left-0.right-0.p-4.flex.justify-center.z-50 {
    bottom: 40px;
} 
}
@include mobile{
  .median{
    display: none !important;
  }
  .newLineMWeb{
    display: none !important;
  }
  .strip-imp{
    display: none !important;
  }
  .strip-closing{
    color: red !important;
  }
}
span.median {
  font-size: 19px;
  padding: 0px 20px;
}

.in-view-element {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.in-view-element.active {
  opacity: 1;
}